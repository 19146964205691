import { FC, useContext } from "react";
import dynamic from "next/dynamic";
import TopBanner from "../Adverts/TopBanner";
import CenterWrapper from "Components/CenterWrapper";
import Frame from "../Frame";
import Action from "./Actions/Action";
import { SectionProps } from "./types";
import Title from "Components/Title";
import FeaturedJobs from "Components/FeaturedJobs";
import { addTypes } from "common/context/Adverts/Adverts";
import { AdvertContext } from "common/context/Adverts";
import Meta from "./Meta";
import Skin from "Components/Skin";
import HeroPlus1 from "./HeroPlus1";
import HeroPlus2 from "./HeroPlus2";
import ArticleX6PlusMPU from "./ArticleX6PlusMPU";
import MultiX2Articles from "./MultiX2Articles";

const NewsLetterSignUp = dynamic(() => import("Components/NewsLetterSignUp"));
const EventSignUp = dynamic(() => import("Components/EventSignUp"));
const ArticleX3PlusMPU = dynamic(
  () => import("./ArticleX3PlusMPU/ArticleX3PlusMPU")
);
const HeroTwo = dynamic(() => import("./HeroTwo"));
const HeroOne = dynamic(() => import("./HeroOne/HeroOne"));
const HeroPlus6 = dynamic(() => import("./HeroPlus6"));
const HeroPlus3 = dynamic(() => import("./HeroPlus3"));
const MostRead = dynamic(() => import("./MostRead"));
const Topics = dynamic(() => import("./Topics"));
const Author = dynamic(() => import("./Author"));
const ArticleX6 = dynamic(() => import("./ArticleX6"));

const Section: FC<SectionProps> = ({ data, menus, jobs }) => {
  const { leaderboard } = useContext<addTypes>(AdvertContext);
  if (!data) {
    return null;
  }
  if (!data.data.components) {
    return null;
  }
  const components = data.data.components;

  const injectAdverts = () => {
    let newSectionBody: any = [];
    let count = 3;
    components.forEach((item: any, i: any) => {
      if (
        item.type === "ArticleX3PlusMPU" ||
        item.type === "ArticleX6PlusMPU" ||
        item.type === "MostPopular"
      ) {
        item.mpu = count;
        newSectionBody.push(item);
        count++;
      } else {
        newSectionBody.push(item);
      }
    });
    return newSectionBody;
  };
  return (
    <Frame data={menus}>
      <Meta data={data} />
      <div style={{ marginBottom: 40 }}>
        <CenterWrapper>
          <Skin id={`wp`} />
          {leaderboard && <TopBanner />}
          <Topics
            isTopic={data.data.type === "topic"}
            name={data.data.section.name}
          />
          <Author
            isAuthor={data.data.type === "author"}
            name={data.data.section?.name}
            image={data.data.section?.imageUrl}
          />
          {injectAdverts().map((data: any, i: number) => {
            const { type } = data;
            switch (type) {
              case "Title":
                return (
                  <Title key={i} type="section">
                    {data.labelText}
                  </Title>
                );
              case "HeroPlus1Articles":
                return <HeroPlus1 key={i} data={data} />;
              case "HeroPlus2Articles":
                return <HeroPlus2 key={i} data={data} />;
              case "HeroPlus3Articles":
                return <HeroPlus3 key={i} data={data} />;
              case "HeroPlus6Articles":
                return <HeroPlus6 key={i} data={data} />;
              case "Services":
                return <Action key={i} type={data.service} />;
              case "ArticleX6PlusMPU":
                return <ArticleX6PlusMPU mpu={true} mpu_id={data.mpu} key={i} data={data} />;
              case "ArticleX3PlusMPU":
                return <ArticleX3PlusMPU mpu_id={data.mpu} key={i} data={data} />;
              case "ArticleX6":
                return <ArticleX6 key={i} data={data} />;
              case "Multi2Articles":
                return <MultiX2Articles key={i} data={data} />;
              case "NewsLetterSignup":
                return <NewsLetterSignUp key={i} />;
              case "NewsLetterSignupEvents":
                return <EventSignUp key={i} />;
              case "MostPopularPlusMPU":
                return <MostRead data={data} key={i} mpu_id={data.mpu} />;
              default:
                return null;
            }
          })}
          <FeaturedJobs jobs={jobs} />
        </CenterWrapper>
      </div>
    </Frame>
  );
};

export default Section;
