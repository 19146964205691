import { Wrapper } from "./TopBanner.styled";
import Ad from "Components/Ad";
import { FC, useContext, useEffect } from "react";
import { useAdsContext } from "common/context/Ads/ads";
import { useMediaQuery } from "react-responsive";
import { defaultBreakpoints } from "styled-media-query";
import { dfp } from "common/ads/dfp";
import { publicationTypes } from "common/context/Publication/PublicationContext";
import { PublicationContext } from "common/context/Publication";
import { useRouter } from "next/router";

interface Props {
  type?: string;
}

const TopBanner: FC<Props> = ({ type }) => {
  const router = useRouter();
  const { config } = useContext<publicationTypes>(PublicationContext);

  const isSmallScreen = useMediaQuery({
    query: `(max-width: ${defaultBreakpoints.small})`,
  });
  const isMediaScreen = useMediaQuery({
    query: `(max-width: ${defaultBreakpoints.large})`,
  });
  const renderTopBar = () => {
    if (isSmallScreen) {
      return [[320, 50]];
    }
    if (isMediaScreen) {
      return [[728, 90]];
    } else {
      return [[970, 250]];
    }
  };


  if (type === "Commercial") {
    return null;
  }
  return (
    <Wrapper>
      <Ad className="topbar" id="leaderboard" width="100%" height="200" />
    </Wrapper>
  );
};

export default TopBanner;
