import styled from "styled-components";
import { defaultBreakpoints } from "styled-media-query";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  justify-items: center;
  min-height: 250px;
  margin-bottom: 31px;
  @media (max-width: ${defaultBreakpoints.large}) {
    min-height: 100px;
  }
  .topbar {
    margin: auto;
    display: flex;
    justify-self: center;
    justify-content: center;
    justify-items: center;
    @media (max-width: ${defaultBreakpoints.large}) {
      max-width: 100vw;
      overflow-x: hidden;
    }
  }
`;
